import { gql } from "@apollo/client";

export const BundleProductFragment = gql`
  fragment BundleProductFields on BundleProduct {
    items {
      option_id
      uid
      title
      sku
      type
      required
      position
      options {
        uid
        id
        label
        quantity
        can_change_quantity
        price
        price_type
        is_default
        position
        product {
          uid
          id
          name
          unit_text
          sku
          specifications {
            label
            value
          }
          __typename
          only_x_left_in_stock
          enable_site_visit
          enable_appliance_site_visit
          care_instructions
          warranty_text
          warranty_appliances
          fitting_instructions
          stock_status
          description {
            html
          }
          description_text
          short_description {
            html
          }
          special_price
          small_image {
            url
          }
          media_gallery {
            url
            label
          }
          small_image {
            url
            label
          }
          product_video {
            label
            url
          }
          price {
            regularPrice {
              amount {
                value
                currency
              }
            }
          }
          __typename
        }
      }
    }
  }
`;
