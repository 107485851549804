import { combineReducers } from "redux";
import loginSliceReducer from "../features/login/LoginSlice";
import cartSliceReducer from "../features/Cart/CartSlice";
import compareListSliceReducer from "../features/CompareList/CompareListSlice";
import RecentlyViewSlice from "../features/recentlyView/RecentlyViewSlice";
import fullPageLoadingSliceReducer from "../features/FullPageLoading/FullPageLoadingSlice";
import megaMenuSliceReducer from "../features/MegaMenu/MegaMenuSlice";
import homePageSliceReducer from "../features/HomePage/HomePageSlice";

export default combineReducers({
  homePageSlice: homePageSliceReducer,
  loginSlice: loginSliceReducer,
  cartSlice: cartSliceReducer,
  compareListSlice: compareListSliceReducer,
  recentlyViewed: RecentlyViewSlice,
  fullPageLoadingSlice: fullPageLoadingSliceReducer,
  megaMenuSlice: megaMenuSliceReducer,
});
