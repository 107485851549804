import {createContext, useContext, useMemo} from 'react'
import {
    GET_COMPARE_LIST,
    GET_COMPARE_LIST_AFTER_SIGNIN,
} from '../Pregrine/ComparePage/comparePage.gql'
import {useLazyQuery} from '@apollo/client'
import {useSelector} from 'react-redux'
import {useState, useEffect, useCallback} from 'react'

const CompareContext = createContext()

export const CompareProvider = ({children}) => {
    const {isSignedIn} = useSelector((state) => state.loginSlice)
    const {compareListId} = useSelector((state) => state.compareListSlice)
    const [data, setData] = useState(null)

    // Combine queries into one to avoid having two hooks.
    const [getCompareList, {data: fetchedData, loading}] = useLazyQuery(
        isSignedIn ? GET_COMPARE_LIST_AFTER_SIGNIN : GET_COMPARE_LIST,
        {
            variables: isSignedIn ? {} : {compareListId},
            fetchPolicy: 'cache-and-network',
            skip: !compareListId && !isSignedIn, // Only fetch if needed
        }
    )

    // Memoize the setData function to avoid re-triggering on each render
    const setDataBasedOnSignInStatus = useCallback(() => {
        if (isSignedIn && fetchedData?.customer?.compare_list?.items) {
            setData(fetchedData.customer.compare_list.items)
        } else if (!isSignedIn && fetchedData?.compareList?.items) {
            setData(fetchedData.compareList.items)
        }
    }, [isSignedIn, fetchedData])

    // Use effect to trigger query when the sign-in status changes
    useEffect(() => {
        getCompareList()
    }, [isSignedIn, compareListId, getCompareList])

    // Use effect to update data when query results change
    useEffect(() => {
        setDataBasedOnSignInStatus()
    }, [fetchedData, setDataBasedOnSignInStatus])

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            data,
            loading,
            getCompareList,
            setData,
        }),
        [data, loading, getCompareList]
    )

    return (
        <CompareContext.Provider value={contextValue}>
            {children}
        </CompareContext.Provider>
    )
}

export const useCompare = () => {
    return useContext(CompareContext)
}
