import { createSlice } from "@reduxjs/toolkit";

export const RecentlyViewedSlice = createSlice({
  name: "recentlyViewed",
  initialState: [],
  reducers: {
    setViewData: (state, action) => {
      if (Array.isArray(action.payload)) {
        const filteredData = action.payload
          .filter((rvItem) => {
            return !state.find((e) => e.id === rvItem.id);
          })
          .reverse();
        state = state.push(...filteredData);
      } else if (!state.find((item) => item.id === action.payload.id)) {
        state.unshift(action.payload);
      } else state = state;
    },
    resetViewData: (state) => {
      return [];
    },
  },
});

export const { setViewData, resetViewData } = RecentlyViewedSlice.actions;

export default RecentlyViewedSlice.reducer;
