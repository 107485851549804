import { createSlice } from "@reduxjs/toolkit";

export const homePageSlice = createSlice({
  name: "ConfigData",
  initialState: {
    ConfigData: {},
  },
  reducers: {
    saveConfigData: (state, action) => {
      state.ConfigData = action.payload;
    },
  },
});

export const { saveConfigData } = homePageSlice.actions;

export default homePageSlice.reducer;
