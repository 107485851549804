import { createSlice } from "@reduxjs/toolkit";

export const megaMenuSlice = createSlice({
  name: "megaMenu",
  initialState: {
    megaMenu: true,
  },
  reducers: {
    saveMegaMenu: (state, action) => {
      state.megaMenu = action.payload;
    },
  },
});

export const { saveMegaMenu } = megaMenuSlice.actions;

export default megaMenuSlice.reducer;
