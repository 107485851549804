import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartId: "",
  },
  reducers: {
    saveCartId: (state, action) => {
      state.cartId = action.payload;
    },
  },
});

export const { saveCartId } = cartSlice.actions;

export default cartSlice.reducer;
