import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export function ApolloProviderClient({ children }) {
  const { isSignedIn } = useSelector((state) => state.loginSlice);

  // HTTP link for GraphQL endpoint
  const httpLink = useMemo(
    () =>
      createHttpLink({
        uri: `https://backend.hettichindiaonline.com/pub/graphql`,
      }),
    []
  );

  // Set up authentication headers dynamically
  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            Authorization: isSignedIn ? `Bearer ${isSignedIn}` : "",
          },
        };
      }),
    [isSignedIn]
  );

  // Create Apollo Client and memoize it
  const client = useMemo(
    () =>
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      }),
    [authLink, httpLink]
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
