import { gql } from "@apollo/client";
import { BundleProductFragment } from "../RootComponents/Product/BundleProductFragment.gql";

export const CREATE_COMPARE_LIST = gql`
  mutation createCompareList {
    createCompareList {
      uid
    }
  }
`;

export const CREATE_COMPARE_LIST_AFTER_SIGN_IN = gql`
  query {
    customer {
      compare_list {
        uid
      }
    }
  }
`;

export const GET_COMPARE_LIST = gql`
  query getCompareList($compareListId: ID!) {
    compareList(uid: $compareListId) {
      uid
      item_count
      attributes {
        code
        label
      }
      items {
        uid
        product {
          id
          enable_site_visit
          enable_appliance_site_visit
          uid
          image {
            url
          }
          small_image {
            url
          }
          attributes {
            label
            value
          }
          description {
            html
          }
          short_description {
            html
          }
          sku
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
          }
          stock_status
          type_id

          media_gallery {
            url
            label
          }
          url_key
          sku
          name
          description {
            html
          }
          simplebundle
          ... on BundleProduct {
            ...BundleProductFields
          }
          ... on ConfigurableProduct {
            configurable_options {
              attribute_code
              attribute_id
              id
              label
              values {
                uid
                default_label
                label
                store_label
                use_default_value
                value_index
                swatch_data {
                  ... on ImageSwatchData {
                    thumbnail
                  }
                  value
                }
              }
            }
            variants {
              attributes {
                code
                value_index
              }
              product {
                id
                uid

                media_gallery {
                  url
                  label
                }
                sku
                stock_status
                price {
                  regularPrice {
                    amount {
                      currency
                      value
                    }
                  }
                }
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                    final_price {
                      value
                      currency
                    }
                    discount {
                      amount_off
                      percent_off
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${BundleProductFragment}
`;

export const GET_COMPARE_LIST_AFTER_SIGNIN = gql`
  query getCompareListAfterSignin {
    customer {
      compare_list {
        uid
        item_count
        attributes {
          code
          label
        }
        items {
          uid
          product {
            id
            enable_site_visit
            enable_appliance_site_visit
            uid
            image {
              url
            }
            small_image {
              url
            }
            attributes {
              label
              value
            }
            description {
              html
            }
            short_description {
              html
            }
            sku
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
            }
            stock_status
            type_id

            media_gallery {
              url
              label
            }
            url_key
            sku
            name
            description {
              html
            }
            ... on BundleProduct {
              ...BundleProductFields
            }
            ... on ConfigurableProduct {
              configurable_options {
                attribute_code
                attribute_id
                id
                label
                values {
                  uid
                  default_label
                  label
                  store_label
                  use_default_value
                  value_index
                  swatch_data {
                    ... on ImageSwatchData {
                      thumbnail
                    }
                    value
                  }
                }
              }
              variants {
                attributes {
                  code
                  value_index
                }
                product {
                  id
                  uid

                  media_gallery {
                    url
                    label
                  }
                  sku
                  stock_status
                  price {
                    regularPrice {
                      amount {
                        currency
                        value
                      }
                    }
                  }
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                      discount {
                        amount_off
                        percent_off
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${BundleProductFragment}
`;

export const ADD_ITEM_COMPARE_LIST = gql`
  mutation addProductsToCompareList($CompareListId: ID!, $productId: [ID]!) {
    addProductsToCompareList(
      input: { uid: $CompareListId, products: $productId }
    ) {
      uid
      item_count
      attributes {
        code
        label
      }
      items {
        uid
        product {
          id
          sku
          name
          url_key
          image {
            url
          }
          small_image {
            url
          }
          description {
            html
          }
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
          }
          ... on BundleProduct {
            ...BundleProductFields
          }
        }
      }
    }
  }
  ${BundleProductFragment}
`;

export const REMOVE_PRODUCTS_FROM_COMPARE_LIST = gql`
  mutation removeProductsFromCompareList(
    $CompareListId: ID!
    $productId: [ID]!
  ) {
    removeProductsFromCompareList(
      input: { uid: $CompareListId, products: $productId }
    ) {
      uid
      item_count
      attributes {
        code
        label
      }
      items {
        uid
        product {
          id
          sku
          name
          url_key
          image {
            url
          }
          small_image {
            url
          }
          description {
            html
          }
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
          }
          ... on BundleProduct {
            ...BundleProductFields
          }
        }
      }
    }
  }
  ${BundleProductFragment}
`;

export const ADD_PRODUCT_WITHOUT_COMPARE_LIST = gql`
  mutation addProductWithoutCompareList($products: [ID]!) {
    createCompareList(input: { products: $products }) {
      uid
      item_count
      attributes {
        code
        label
      }
      items {
        uid
        product {
          id
          sku
          name
          url_key
          image {
            url
          }
          small_image {
            url
          }
          description {
            html
          }
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
          }
          ... on BundleProduct {
            ...BundleProductFields
          }
        }
      }
    }
  }
  ${BundleProductFragment}
`;

export default {
  createCompareListQuery: CREATE_COMPARE_LIST,
  getCompareListQuery: GET_COMPARE_LIST,
  addProductsToCompareList: ADD_ITEM_COMPARE_LIST,
  removeProductsFromCompareListMutation: REMOVE_PRODUCTS_FROM_COMPARE_LIST,
  createComparelistIdAfterSignInQuery: CREATE_COMPARE_LIST_AFTER_SIGN_IN,
  addProductWithoutCompareList: ADD_PRODUCT_WITHOUT_COMPARE_LIST,
};
