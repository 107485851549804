import React, {Suspense, lazy} from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import store from './app/store'
import './index.css'
import {PersistGate} from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist'
import {ToastProvider} from 'react-toast-notifications'
// import "bootstrap/dist/css/bootstrap.min.css"; // Consider loading this asynchronously
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "swiper/swiper-bundle.min.css";
import {ApolloProviderClient} from './Wrappers/apolloClient'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import {CompareProvider} from './context/CompareContext'
import {BrowserRouter as Router} from 'react-router-dom'
// import "font-awesome/css/font-awesome.min.css";

const App = lazy(() => import('./App')) // Lazy load the App component
const persistor = persistStore(store)

const container = document.getElementById('root')
const root = createRoot(container) // Create root using createRoot

root.render(
    process.env.NODE_ENV !== 'production' ? (
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ToastProvider
                        autoDismiss
                        autoDismissTimeout={4000}
                        placement="top-center">
                        <ApolloProviderClient>
                            <CompareProvider>
                                <Router>
                                    <Suspense
                                        fallback={
                                            <div className="loading-spinner"></div>
                                        }>
                                        <App />
                                    </Suspense>
                                </Router>
                            </CompareProvider>
                        </ApolloProviderClient>
                    </ToastProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    ) : (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ToastProvider
                    autoDismiss
                    autoDismissTimeout={4000}
                    placement="top-center">
                    <ApolloProviderClient>
                        <CompareProvider>
                            <Router>
                                <Suspense
                                    fallback={
                                        <div className="loading-spinner"></div>
                                    }>
                                    <App />
                                </Suspense>
                            </Router>
                        </CompareProvider>
                    </ApolloProviderClient>
                </ToastProvider>
            </PersistGate>
        </Provider>
    )
)

// Register the service worker for better caching and offline capabilities
serviceWorkerRegistration.register()
